define("nodes/custom-drivers/cluster-drivers/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HEADERS = [{
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['displayName'],
    searchField: 'displayName',
    translationKey: 'generic.name'
  }];

  var _default = Ember.Controller.extend({
    growl: Ember.inject.service(),
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    headers: HEADERS,
    refreshing: false,
    actions: {
      addNewDriver: function addNewDriver() {
        var newDriver = Ember.get(this, 'globalStore').createRecord({
          type: 'kontainerDriver',
          name: null,
          description: null,
          checksum: null,
          url: null,
          active: true
        });
        this.get('modalService').toggleModal('modal-edit-driver', newDriver);
      },
      refreshMetadata: function refreshMetadata() {
        var _this = this;

        Ember.set(this, 'refreshing', true);
        Ember.get(this, 'globalStore').rawRequest({
          url: '/v3/kontainerdrivers?action=refresh',
          method: 'POST'
        }).catch(function (error) {
          Ember.get(_this, 'growl').fromError(error.message);
        }).finally(function () {
          return Ember.set(_this, 'refreshing', false);
        });
      }
    },
    rows: Ember.computed('model.drivers.@each.{state,id,version,externalId}', function () {
      // possibly add some search here
      var drivers = Ember.get(this, 'model.drivers.content');
      return drivers;
    })
  });

  _exports.default = _default;
});