define("shared/components/form-members-catalog-access/component", ["exports", "shared/components/form-members-catalog-access/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MEMBERS_HEADERS = [{
    translationKey: 'newMultiClusterApp.members.table.name',
    name: 'name',
    sort: ['userPrincipalId', 'groupPrincipalId']
  }, {
    translationKey: 'newMultiClusterApp.members.table.type',
    name: 'type',
    sort: ['displayType']
  }];

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    membersHeaders: MEMBERS_HEADERS,
    sortBy: '',
    descending: false,
    excludeMember: false,
    resource: null,
    gotError: null,
    removeMember: null,
    searchOnlyGroups: false,
    includeLocal: false,
    actions: {
      addPrincipal: function addPrincipal(principal) {
        if (principal) {
          var principalType = principal.principalType,
              id = principal.id;
          var nue = {
            type: 'member',
            displayType: Ember.get(principal, 'displayType') || principalType,
            displayName: Ember.get(principal, 'displayName') || Ember.get(principal, 'loginName') || Ember.get(principalType, 'id'),
            principalType: principalType,
            id: id
          };
          this.addAuthorizedPrincipal(nue);
        }
      }
    },
    addAuthorizedPrincipal: function addAuthorizedPrincipal() {
      throw new Error('add principal handler must be provided!!');
    }
  });

  _exports.default = _default;
});