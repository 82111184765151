define("global-admin/components/form-global-roles/component", ["exports", "global-admin/components/form-global-roles/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var USER = 'user';
  var ADMIN = 'admin';
  var CUSTOM = 'custom';

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    layout: _template.default,
    user: null,
    mode: null,
    allRoles: null,
    custom: null,
    _boundSave: null,
    init: function init() {
      this._super.apply(this, arguments);

      var all = Ember.set(this, 'allRoles', Ember.get(this, 'globalStore').all('globalRole'));
      var user = Ember.get(this, 'user');
      var hasCustom = !!Ember.get(user, 'globalRoleBindings').map(function (x) {
        return Ember.get(x, 'globalRole');
      }).findBy('isCustom');
      var current = Ember.get(user, 'globalRoleBindings');
      var mode = CUSTOM;

      if (Ember.get(user, 'hasAdmin')) {
        mode = ADMIN;
      } else if ((!Ember.get(user, 'id') || Ember.get(user, 'hasUser')) && !hasCustom) {
        mode = USER;
      }

      Ember.set(this, 'mode', mode);
      Ember.set(this, 'custom', all.filterBy('isHidden', false).map(function (role) {
        var binding = current.findBy('globalRole', role) || null;
        return {
          role: role,
          active: !!binding,
          existing: binding
        };
      }));
      Ember.set(this, '_boundSave', this.save.bind(this));
      Ember.get(this, 'setSave')(Ember.get(this, '_boundSave'));
    },
    actions: {
      toggle: function toggle(e) {
        var $target = $(e.target); // eslint-disable-line

        var $row = $target.closest('.input-group');
        var check = $('input[type=checkbox]', $row)[0]; // eslint-disable-line

        if (check && e.target !== check && e.target.tagName !== 'LABEL') {
          check.click();
        }
      }
    },
    unsetCustom: Ember.on('init', Ember.observer('mode', function () {
      if (Ember.get(this, 'mode') !== 'custom') {
        Ember.get(this, 'custom').forEach(function (c) {
          Ember.set(c, 'active', false);
        });
      }
    })),
    make: function make(role) {
      return Ember.get(this, 'globalStore').createRecord({
        type: 'globalRoleBinding',
        globalRoleId: Ember.get(role, 'id'),
        userId: Ember.get(this, 'user.id'),
        subjectKind: 'User'
      });
    },
    save: function save() {
      var _this = this;

      this.set('errors', null);
      var add = [];
      var remove = [];
      var user = Ember.get(this, 'user');
      var custom = Ember.get(this, 'custom');
      var baseRole = Ember.get(this, 'allRoles').findBy('isBase', true);
      var userRole = Ember.get(this, 'allRoles').findBy('isUser', true);
      var adminRole = Ember.get(this, 'allRoles').findBy('isAdmin', true);
      var mode = Ember.get(this, 'mode');

      switch (mode) {
        case ADMIN:
          remove = custom.filterBy('existing').filter(function (x) {
            return Ember.get(x, 'role.isUser') || Ember.get(x, 'role.isBase') || Ember.get(x, 'role.isCustom');
          }).map(function (x) {
            return x.existing;
          });

          if (!Ember.get(user, 'hasAdmin')) {
            add.push(this.make(adminRole));
          }

          break;
        // Admin intentionally doesn't remove any roles

        case USER:
          remove = custom.filterBy('existing').filter(function (x) {
            return Ember.get(x, 'role.isAdmin') || Ember.get(x, 'role.isBase') || Ember.get(x, 'role.isCustom');
          }).map(function (x) {
            return x.existing;
          });

          if (!Ember.get(user, 'hasUser')) {
            add.push(this.make(userRole));
          }

          break;

        case CUSTOM:
          add = custom.filterBy('active', true).filterBy('existing', null).map(function (x) {
            return _this.make(x.role);
          });
          remove = custom.filterBy('active', false).filterBy('existing').map(function (y) {
            return y.existing;
          });

          if (!Ember.get(user, 'hasBase')) {
            add.push(this.make(baseRole));
          }

          break;
      } // Remove the standard roles, but don't remove anything for admins


      var bindings = Ember.get(user, 'globalRoleBindings');

      if (mode !== ADMIN) {
        remove.pushObjects(bindings.filterBy('globalRole.isAdmin'));

        if (mode !== USER) {
          remove.pushObjects(bindings.filterBy('globalRole.isUser'));
        }

        if (mode !== CUSTOM) {
          remove.pushObjects(bindings.filterBy('globalRole.isBase'));
        }
      }

      return Ember.RSVP.all(add.map(function (x) {
        return x.save();
      })).then(function () {
        return Ember.RSVP.all(remove.map(function (x) {
          return x.delete();
        })).then(function () {
          return true;
        });
      });
    }
  });

  _exports.default = _default;
});