define("global-admin/multi-cluster-apps/catalog/index/controller", ["exports", "ui/utils/platform"], function (_exports, _platform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    catalogController: Ember.inject.controller('multi-cluster-apps.catalog'),
    parentRoute: 'multi-cluster-apps.catalog',
    launchRoute: 'multi-cluster-apps.catalog.launch',
    category: Ember.computed.alias('catalogController.category'),
    actions: {
      categoryAction: function categoryAction(category) {
        this.transitionToRoute(this.get('parentRoute'), {
          queryParams: {
            category: category
          }
        });
      },
      launch: function launch(id, onlyAlternate) {
        if (onlyAlternate && !(0, _platform.isAlternate)(event)) {
          return false;
        }

        this.transitionToRoute(this.get('launchRoute'), id);
      },
      refresh: function refresh() {
        var catalogTab = Ember.getOwner(this).lookup('route:catalog-tab');
        catalogTab.send('refresh');
      }
    }
  });

  _exports.default = _default;
});